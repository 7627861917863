/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import {
  useAreaSearchQuery,
  useSessionsFeedLazyQuery
} from "@goodgym/graphql/hooks";
import React from "react";

export type SessionSearchProps = UI.BoxProps & {
  fullWidth?: boolean;
  socialVisit?: boolean;
  tag?: string | null;
};

const SessionSearch: React.FC<SessionSearchProps> = ({ tag, ...props }) => {
  const [postcode, setPostcode] = React.useState(null);

  const [areaId, setAreaId] = React.useState("all");

  const variables: {
    from: Date;
    types: string[];
    areaIds: string[] | [];
    postcode: string | null;
    maxDistance: number;
    tag: string| null;
    limit: number;
  } = {
    from: new Date(),
    types: [
      "GroupRun",
      "CommunityMission",
      "Mission",
      "DocumentCheck",
      "Party",
      "TrainingSession",
      "Race",
    ],
    areaIds: [],
    postcode: postcode,
    maxDistance: 10,
    tag: tag ? tag : null,
    limit: 10,
  };

  const [filters, setFilters] = React.useState(variables);

 const [fetchSessions, { error:errors, data:sessions }] = useSessionsFeedLazyQuery({
   fetchPolicy: "cache-and-network",
 });


  const { data, error } = useAreaSearchQuery({
    variables: {},
  });
  const allSessions = sessions?.sessions.results;

  const handleAreaClick = () => {
    setPostcode(null);
  };

  React.useEffect((
  
  ) => {
    fetchSessions({
    variables: filters
  })},[filters, areaId, fetchSessions])
  if (error || errors) return <UI.Error error={error} />;
  if (!data) return <UI.Loading />;
  return (
    <UI.Box {...props}>
      <UI.Container>
        <UI.Typography
          variant="h2"
          sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
        >
          Find sessions in your area
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.FormControl sx={{ width: "350px" }}>
          <UI.InputLabel id="area-select-label">Areas</UI.InputLabel>
          <UI.Select
            id="area-select-label"
            value={areaId}
            label="Area"
            onChange={(e) => {
              setAreaId(e.target.value);
              setFilters((currentFilters) => ({
                ...currentFilters,
                areaIds: e.target.value === "all"? []: [e.target.value],
              }));
              handleAreaClick();
            }}
            sx={{ mb: 3 }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAreaClick();
              }
            }}
          >
            <UI.MenuItem value="all">All Areas</UI.MenuItem>
            {data?.areas.map((area) => (
              <UI.MenuItem key={area.id} value={area.id} aria-label={area.name}>
                {area.name}
              </UI.MenuItem>
            ))}
          </UI.Select>
        </UI.FormControl>
      </UI.Container>
      {allSessions?.length === 0 && (
        <UI.Typography
          variant="h5"
          sx={{ mb: 2, textAlign: "center", fontStyle: "normal" }}
        >
          Sorry, no sessions in your area
        </UI.Typography>
      )}
      <UI.Grid container spacing={3}>
        {allSessions?.map((session, index) => (
          <UI.Grid key={index} item xs={12} md={6}>
            <UI.ListingCard january={true} listing={session} />
          </UI.Grid>
        ))}
      </UI.Grid>
    </UI.Box>
  );
};

export default SessionSearch;
